import React, { useState, useEffect, useCallback } from 'react';
import CouponList from '../components/CouponList';

const Home = ({ searchOption }) => {
    const [coupons, setCoupons] = useState([]);
    const [filteredCoupons, setFilteredCoupons] = useState([]);
    const [pageContent, setPageContent] = useState({
        title: 'DoorDash Promo Codes',
        description: 'Save on your next meal with DoorDashPromoCode.net. Find the latest offers and enjoy delicious deals today!',
        section1Title: 'About DoorDash Promo Codes',
        section1Content: 'DoorDash promo codes are pre-set discount codes that DoorDash offers to specific user groups. These codes are designed to provide discounts to eligible users and are typically more favorable than publicly available offers.\n\nUsers can obtain these promo codes through various means, including new user sign-ups, friend referrals, participation in promotional events, or by becoming loyal customers. In return, DoorDash offers discounted prices to eligible users, which can result in savings of up to 50% or more on orders.\n\nDoorDash promo codes are a strategic way for DoorDash to attract new users and foster long-term customer relationships. These codes are often unique and frequently come with additional benefits such as free delivery, restaurant discounts, first-order specials, and reward point accumulation.\n\nDoorDash promo codes provide users with the opportunity to save on expenses while enjoying their favorite meals, ensuring they have access to convenient and affordable food delivery services. Through our website, DoorDashPromoCode.net, users can easily find and use the latest and most valuable DoorDash promotional codes, maximizing their savings on every order.',
        section2Title: 'What Are DoorDash Promo Codes?',
        section2Content: 'DoorDash promo codes are special discount offers provided by DoorDash, a leading food delivery platform. These codes are designed to offer savings to customers on their food orders and deliveries. Promo codes can be obtained through various channels, including official promotions, partnerships, and dedicated websites like DoorDashPromoCode.net.',
        section3Title: 'How to Use DoorDash Promo Codes?',
        section3Content: `Follow these easy steps to save money on your next DoorDash order:

Find the Latest Codes:
Visit DoorDashPromoCode.net to discover the most recent and valuable DoorDash coupon codes.

Get Your Code:
Click on the "Get Code" button next to your chosen offer to reveal the promo code.

Order on DoorDash:
• Go to the DoorDash website or open the DoorDash app.
• Enter your delivery address.
• Browse restaurants and select your desired food items.

Checkout Process:
• Proceed to the checkout page.
• Choose your preferred payment method.

Apply the Promo Code:
• Look for the "Promo Code" or "Discount Code" section during checkout.
• Enter the DoorDash promo code you obtained from DoorDashPromoCode.net.
• Click "Apply" to see your savings!

Complete Your Order:
Finish the checkout process and enjoy your discounted meal!

Pro Tip: The process for entering promo codes is the same whether you're using the DoorDash website or the mobile app.

DoorDashPromoCode.net Exclusive: We regularly update our site with the latest DoorDash promo codes and offers. Bookmark our page to ensure you never miss out on great savings for your food delivery orders!

Remember to check the terms and conditions of each promo code, as some may have restrictions such as minimum order values or limited-time availability.

Happy eating and saving with DoorDashPromoCode.net!`
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async (url, retries = 3) => {
        for (let i = 0; i < retries; i++) {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return await response.json();
            } catch (e) {
                console.error(`Attempt ${i + 1} failed: ${e.message}`);
                if (i === retries - 1) throw e;
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    }, []);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const [couponsData, homeData] = await Promise.all([
                    fetchData('https://doordash-promo-content.lejintian.workers.dev/?section=coupons'),
                    fetchData('https://doordash-promo-content.lejintian.workers.dev/?section=home')
                ]);
                setCoupons(couponsData);
                setFilteredCoupons(couponsData);
                setPageContent(prevContent => ({
                    ...prevContent,
                    ...homeData
                }));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load content. Please check your internet connection and try again.');
                setIsLoading(false);
            }
        };

        fetchAllData();
    }, [fetchData]);

    useEffect(() => {
        const filterCoupons = () => {
            let filtered;
            switch (searchOption) {
                case 'doordash promo code $15':
                    filtered = coupons.filter(coupon => 
                        coupon.title.toLowerCase().includes('$15')
                    );
                    break;
                case 'doordash promo code 50 off':
                    filtered = coupons.filter(coupon => 
                        coupon.title.toLowerCase().includes('50 off') ||
                        coupon.title.toLowerCase().includes('50% off')
                    );
                    break;
                case 'doordash promo code first order':
                    filtered = coupons.filter(coupon => 
                        coupon.title.toLowerCase().includes('first order') ||
                        coupon.title.toLowerCase().includes('new user')
                    );
                    break;
                default:
                    filtered = coupons;
            }
            setFilteredCoupons(filtered);
        };

        filterCoupons();
    }, [searchOption, coupons]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="w-full bg-gray-100 py-6 sm:py-12">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl sm:text-4xl font-bold mb-4 sm:mb-6 text-center text-gray-800">{pageContent.title}</h1>
                <p className="text-base sm:text-lg mb-8 sm:mb-12 text-center text-gray-600">
                    {pageContent.description}
                </p>
                {filteredCoupons.length > 0 ? (
                    <CouponList coupons={filteredCoupons} />
                ) : (
                    <p className="text-center text-gray-600">No coupons available for this selection. Please try another option.</p>
                )}
                <div className="mt-8 sm:mt-16">
                    <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">{pageContent.section1Title}</h2>
                    <p className="mb-4 sm:mb-6 text-gray-700 whitespace-pre-line text-sm sm:text-base">{pageContent.section1Content}</p>
                </div>
                <div className="mt-6 sm:mt-8">
                    <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">{pageContent.section2Title}</h2>
                    <p className="text-gray-700 whitespace-pre-line text-sm sm:text-base">{pageContent.section2Content}</p>
                </div>
                <div className="mt-6 sm:mt-8">
                    <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">{pageContent.section3Title}</h2>
                    <p className="text-gray-700 whitespace-pre-line text-sm sm:text-base">{pageContent.section3Content}</p>
                </div>
            </div>
        </div>
    );
};

export default Home;