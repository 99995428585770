import React, { useState, useEffect } from 'react';
import CouponList from '../components/CouponList';

const CouponsPage = () => {
    const [coupons, setCoupons] = useState([]);
    const [pageContent, setPageContent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchCoupons();
        fetchPageContent();
    }, []);

    const fetchCoupons = () => {
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=coupons')
            .then(response => response.json())
            .then(data => {
                console.log('Fetched coupons:', data);
                setCoupons(data);
            })
            .catch(error => {
                console.error('Error fetching coupons:', error);
                setError('Failed to load coupons');
            })
            .finally(() => setIsLoading(false));
    };

    const fetchPageContent = () => {
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=couponsPage')
            .then(response => response.json())
            .then(data => {
                console.log('Fetched page content:', data);
                setPageContent(data);
            })
            .catch(error => {
                console.error('Error fetching page content:', error);
                setError('Failed to load page content');
            })
            .finally(() => setIsLoading(false));
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="w-full bg-gray-100 py-12">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">{pageContent.title}</h1>
                <p className="text-lg mb-12 text-center text-gray-600">
                    {pageContent.description}
                </p>
                {coupons.length > 0 ? (
                    <CouponList coupons={coupons} />
                ) : (
                    <p className="text-center text-gray-600">No coupons available at the moment. Please check back later.</p>
                )}
                <div className="mt-16 bg-white p-8 rounded-lg">
                    <h2 className="text-2xl font-bold mb-4">{pageContent.section1Title}</h2>
                    <p className="mb-6 text-gray-700">{pageContent.section1Content}</p>
                </div>
                <div className="mt-8 bg-white p-8 rounded-lg">
                    <h2 className="text-2xl font-bold mb-4">{pageContent.section2Title}</h2>
                    <p className="text-gray-700">{pageContent.section2Content}</p>
                </div>
                <div className="mt-8 bg-white p-8 rounded-lg">
                    <h2 className="text-2xl font-bold mb-4">{pageContent.section3Title}</h2>
                    <p className="text-gray-700">{pageContent.section3Content}</p>
                </div>
            </div>
        </div>
    );
};

export default CouponsPage;