import React, { useState, useEffect, useCallback } from 'react';

const defaultContent = {
    couponsPage: {
        title: 'DoorDash Promo Codes',
        description: 'Find the latest and best DoorDash promo codes here!',
        section1Title: 'About DoorDash Promo Codes',
        section1Content: 'DoorDash promo codes are special discount offers...',
        section2Title: 'How to Use DoorDash Promo Codes',
        section2Content: 'To use a DoorDash promo code, simply...',
        section3Title: 'Why Choose Our Promo Codes',
        section3Content: 'We regularly update our promo codes to ensure...'
    },
    about: 'About Us content goes here...',
    advertising: 'Advertising Terms content goes here...',
    privacy: 'Privacy Policy content goes here...'
};

const PageContentEditor = () => {
    const [pages, setPages] = useState(defaultContent);
    const [isLoading, setIsLoading] = useState(true);

    const fetchPageContents = useCallback(() => {
        setIsLoading(true);
        Promise.all(Object.keys(defaultContent).map(page => 
            fetch(`https://doordash-promo-content.lejintian.workers.dev/?section=${page}`)
                .then(response => response.json())
                .catch(() => defaultContent[page])
        )).then(results => {
            const newPages = Object.keys(defaultContent).reduce((acc, page, index) => {
                acc[page] = results[index] || defaultContent[page];
                return acc;
            }, {});
            setPages(newPages);
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchPageContents();
    }, [fetchPageContents]);

    const handlePageChange = (page, field, content) => {
        setPages(prevPages => ({
            ...prevPages,
            [page]: typeof prevPages[page] === 'object' 
                ? { ...prevPages[page], [field]: content }
                : content
        }));
    };

    const handleUpdatePage = async (page) => {
        try {
            console.log(`Updating ${page} content:`, pages[page]);
            const response = await fetch('https://doordash-promo-content.lejintian.workers.dev/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    section: page,
                    content: pages[page]
                }),
            });
            console.log(`Update ${page} response:`, response);
            const responseData = await response.json();
            console.log(`Update ${page} response data:`, responseData);
            if (response.ok) {
                alert(`${page} page updated successfully`);
                fetchPageContents(); // 立即重新获取内容
            } else {
                alert(`Failed to update ${page} page: ${responseData.error || 'Unknown error'}`);
            }
        } catch (error) {
            console.error(`Error updating ${page} page:`, error);
            alert(`Error updating ${page} page: ${error.message}`);
        }
    };

    if (isLoading) return <div>Loading...</div>;

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">页面内容编辑</h2>
            {Object.entries(pages).map(([page, content]) => (
                <div key={page} className="mb-8 p-4 border rounded">
                    <h3 className="text-xl font-semibold mb-2">{page}</h3>
                    {typeof content === 'object' ? (
                        Object.entries(content).map(([field, value]) => (
                            <div key={field} className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">{field}</label>
                                <textarea
                                    value={value}
                                    onChange={(e) => handlePageChange(page, field, e.target.value)}
                                    className="w-full p-2 border rounded mb-2"
                                    rows="4"
                                />
                            </div>
                        ))
                    ) : (
                        <textarea
                            value={content}
                            onChange={(e) => handlePageChange(page, null, e.target.value)}
                            className="w-full p-2 border rounded mb-2"
                            rows="6"
                        />
                    )}
                    <button
                        onClick={() => handleUpdatePage(page)}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                        更新 {page} 页面
                    </button>
                </div>
            ))}
        </div>
    );
};

export default PageContentEditor;