import React from 'react';
import { Link } from 'react-router-dom';
import logoWhite from './logowhite.png'; // 更新路径

const Footer = () => (
    <footer className="bg-[#ff3007] text-white py-8">
        <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="flex items-center mb-4 md:mb-0">
                    <img src={logoWhite} alt="DoorDash Promo Code" className="h-8 mr-2" />
                    <span className="text-2xl font-bold font-artistic">doordashpromocode.net</span>
                </div>
                <div className="flex justify-center space-x-4 mb-4 md:mb-0">
                    <Link to="/about" className="hover:underline">About Us</Link>
                    <Link to="/advertising" className="hover:underline">Advertising Terms</Link>
                    <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                </div>
                <div>
                    <span>Copyright © 2024 doordashpromocode.net</span>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;