import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SearchFilter from './SearchFilter';
import logo from './logo.png'; // 导入 Logo

const Navbar = ({ searchOption, onSearchOptionChange }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsSearchOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="bg-white shadow">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="flex items-center w-full sm:w-auto justify-between">
                        <Link to="/" className="flex items-center py-4">
                            <img src={logo} alt="Logo" className="h-8 w-auto" />
                            <span className="ml-2 text-lg font-bold text-gray-900 sm:text-xl" style={{ fontFamily: "'Montserrat', sans-serif" }}>DoorDashPromoCode.net</span>
                        </Link>
                        <div className="sm:hidden">
                            <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open main menu</span>
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} sm:flex sm:items-center w-full sm:w-auto`}>
                        <div className="flex flex-col sm:flex-row sm:items-center">
                            <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Home</Link>
                            <div className="relative" ref={searchRef}>
                                <button 
                                    onClick={() => setIsSearchOpen(!isSearchOpen)}
                                    className="w-full text-left block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                >
                                    Search doordash promo code
                                </button>
                                {isSearchOpen && (
                                    <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <SearchFilter 
                                            searchOption={searchOption} 
                                            onSearchOptionChange={(option) => {
                                                onSearchOptionChange(option);
                                                setIsSearchOpen(false);
                                                setIsMobileMenuOpen(false);
                                            }} 
                                        />
                                    </div>
                                )}
                            </div>
                            <Link to="/help" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Help</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;