import React, { useState, useEffect } from 'react';

const HomeContentEditor = () => {
    const [homeContent, setHomeContent] = useState({
        title: '',
        description: '',
        feature1Title: '',
        feature1Description: '',
        feature2Title: '',
        feature2Description: '',
        feature3Title: '',
        feature3Description: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHomeContent();
    }, []);

    const fetchHomeContent = () => {
        setIsLoading(true);
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=home')
            .then(response => response.json())
            .then(data => {
                console.log('Fetched home content:', data);
                setHomeContent(data || {});
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching home content:', error);
                setError('Failed to fetch home content');
                setIsLoading(false);
            });
    };

    const handleContentChange = (field, value) => {
        setHomeContent(prevContent => ({
            ...prevContent,
            [field]: value
        }));
    };

    const handleUpdateContent = async () => {
        try {
            const response = await fetch('https://doordash-promo-content.lejintian.workers.dev/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    section: 'home',
                    content: homeContent
                }),
            });
            if (response.ok) {
                alert('Home content updated successfully');
                fetchHomeContent(); // 重新获取更新后的内容
            } else {
                alert('Failed to update home content');
            }
        } catch (error) {
            console.error('Error updating home content:', error);
            alert('Error updating home content');
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">首页内容编辑</h2>
            {Object.entries(homeContent).map(([field, value]) => (
                <div key={field} className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">{field}</label>
                    <textarea
                        value={value}
                        onChange={(e) => handleContentChange(field, e.target.value)}
                        className="w-full p-2 border rounded mb-2"
                        rows="4"
                    />
                </div>
            ))}
            <button
                onClick={handleUpdateContent}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
                更新首页内容
            </button>
        </div>
    );
};

export default HomeContentEditor;