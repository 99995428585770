import React, { useState, useEffect } from 'react';

const SearchOptionsEditor = () => {
    const [searchOptions, setSearchOptions] = useState([]);

    useEffect(() => {
        fetchSearchOptions();
    }, []);

    const fetchSearchOptions = () => {
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=searchOptions')
            .then(response => response.json())
            .then(data => {
                console.log('Fetched search options:', data);
                setSearchOptions(data);
            })
            .catch(error => console.error('Error fetching search options:', error));
    };

    const handleOptionChange = (index, value) => {
        setSearchOptions(prevOptions => 
            prevOptions.map((option, i) => i === index ? value : option)
        );
    };

    const handleAddOption = () => {
        setSearchOptions(prevOptions => [...prevOptions, '']);
    };

    const handleRemoveOption = (index) => {
        setSearchOptions(prevOptions => 
            prevOptions.filter((_, i) => i !== index)
        );
    };

    const handleUpdateOptions = async () => {
        try {
            const response = await fetch('https://doordash-promo-content.lejintian.workers.dev/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    section: 'searchOptions',
                    content: searchOptions
                }),
            });
            if (response.ok) {
                alert('Search options updated successfully');
                fetchSearchOptions(); // 立即重新获取数据
            } else {
                alert('Failed to update search options');
            }
        } catch (error) {
            console.error('Error updating search options:', error);
            alert('Error updating search options');
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">搜索选项编辑</h2>
            {searchOptions.map((option, index) => (
                <div key={index} className="flex mb-2">
                    <input
                        type="text"
                        value={option}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        className="flex-grow p-2 border rounded mr-2"
                    />
                    <button
                        onClick={() => handleRemoveOption(index)}
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600"
                    >
                        删除
                    </button>
                </div>
            ))}
            <button
                onClick={handleAddOption}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2"
            >
                添加选项
            </button>
            <button
                onClick={handleUpdateOptions}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
                更新搜索选项
            </button>
        </div>
    );
};

export default SearchOptionsEditor;