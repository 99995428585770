import React, { useState, useEffect } from 'react';

const PrivacyPolicy = () => {
    const [privacyContent, setPrivacyContent] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=privacy')
            .then(response => response.json())
            .then(data => {
                setPrivacyContent(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching privacy policy content:', error);
                setError('Failed to load privacy policy content');
                setIsLoading(false);
            });
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <div className="whitespace-pre-wrap">{privacyContent}</div>
        </div>
    );
};

export default PrivacyPolicy;