import React from 'react';

const SearchFilter = ({ searchOption, onSearchOptionChange }) => {
    return (
        <div className="py-1">
            {['all', 'doordash promo code $15', 'doordash promo code 50 off', 'doordash promo code first order'].map((option) => (
                <button
                    key={option}
                    className={`block w-full text-left px-4 py-2 text-sm ${searchOption === option ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} hover:bg-gray-100 hover:text-gray-900`}
                    onClick={() => onSearchOptionChange(option)}
                >
                    {option === 'all' ? 'All Promo Codes' : option}
                </button>
            ))}
        </div>
    );
};

export default SearchFilter;