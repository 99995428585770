import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const AdminPage = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        navigate('/login');
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold">Admin Panel</h1>
                <button 
                    onClick={handleLogout}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                >
                    登出
                </button>
            </div>
            <nav className="mb-6">
                <ul className="flex flex-wrap space-x-4">
                    <li><Link to="/admin/coupons" className="text-blue-500 hover:underline">优惠券编辑</Link></li>
                    <li><Link to="/admin/home" className="text-blue-500 hover:underline">首页内容编辑</Link></li>
                    <li><Link to="/admin/pages" className="text-blue-500 hover:underline">其他页面内容编辑</Link></li>
                    <li><Link to="/admin/search-options" className="text-blue-500 hover:underline">搜索选项编辑</Link></li>
                </ul>
            </nav>
        </div>
    );
};

export default AdminPage;