import React from 'react';

const Help = () => {
    return (
        <div className="max-w-3xl mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-6">DoorDash Promo Codes: Quick Guide</h1>
            
            <h2 className="text-2xl font-semibold mt-6 mb-4">What Are DoorDash Promo Codes?</h2>
            <p className="mb-4">DoorDash promo codes are discount offers that can save you money on food delivery orders. These codes are available through various channels, including DoorDashPromoCode.net.</p>
            
            <h3 className="text-xl font-semibold mt-4 mb-2">Benefits:</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Save up to 50% or more on orders</li>
                <li>Access exclusive deals like free delivery and restaurant discounts</li>
                <li>Ideal for new users, referrals, and loyal customers</li>
            </ul>
            
            <h3 className="text-xl font-semibold mt-4 mb-2">How to Use:</h3>
            <ol className="list-decimal pl-6 mb-4">
                <li>Visit DoorDashPromoCode.net for the latest codes</li>
                <li>Click "Get Code" on your chosen offer</li>
                <li>Go to DoorDash website or app</li>
                <li>Select your food items</li>
                <li>At checkout, enter the promo code</li>
                <li>Apply and enjoy your savings!</li>
            </ol>
            
            <h3 className="text-xl font-semibold mt-4 mb-2">Pro Tips:</h3>
            <ul className="list-disc pl-6 mb-4">
                <li>Works the same on website and mobile app</li>
                <li>Check terms and conditions for each code</li>
                <li>Bookmark DoorDashPromoCode.net for regular updates</li>
            </ul>
            
            <p className="mt-6 font-semibold">Save smart and eat well with DoorDashPromoCode.net!</p>
        </div>
    );
};

export default Help;