import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages/Home';
import CouponsPage from './pages/CouponsPage';
import About from './pages/About';
import AdminPage from './pages/AdminPage';
import CouponEditor from './pages/CouponEditor';
import HomeContentEditor from './pages/HomeContentEditor';
import PageContentEditor from './pages/PageContentEditor';
import SearchOptionsEditor from './pages/SearchOptionsEditor';
import LoginPage from './pages/LoginPage';
import AdvertisingPage from './pages/AdvertisingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Navbar from './components/Navbar';
import Help from './pages/Help'; // 导入新的 Help 组件
import NotFound from './pages/NotFound';

function App() {
    const [searchOption, setSearchOption] = useState('');

    const handleSearchOptionChange = (option) => {
        setSearchOption(option);
    };

    return (
        <Router>
            <div className="flex flex-col min-h-screen bg-white">
                <Navbar 
                    searchOption={searchOption} 
                    onSearchOptionChange={handleSearchOptionChange} 
                />
                <main className="flex-grow w-full">
                    <Routes>
                        <Route path="/" element={<Home searchOption={searchOption} />} />
                        <Route path="/coupons" element={<CouponsPage />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/help" element={<Help />} /> {/* 添加新的 Help 路由 */}
                        <Route path="/login" element={<LoginPage />} />
                        <Route 
                            path="/admin" 
                            element={
                                localStorage.getItem('isLoggedIn') === 'true' 
                                    ? <AdminPage /> 
                                    : <LoginPage />
                            } 
                        />
                        <Route 
                            path="/admin/coupons" 
                            element={
                                localStorage.getItem('isLoggedIn') === 'true' 
                                    ? <CouponEditor /> 
                                    : <LoginPage />
                            } 
                        />
                        <Route 
                            path="/admin/home" 
                            element={
                                localStorage.getItem('isLoggedIn') === 'true' 
                                    ? <HomeContentEditor /> 
                                    : <LoginPage />
                            } 
                        />
                        <Route 
                            path="/admin/pages" 
                            element={
                                localStorage.getItem('isLoggedIn') === 'true' 
                                    ? <PageContentEditor /> 
                                    : <LoginPage />
                            } 
                        />
                        <Route 
                            path="/admin/search-options" 
                            element={
                                localStorage.getItem('isLoggedIn') === 'true' 
                                    ? <SearchOptionsEditor /> 
                                    : <LoginPage />
                            } 
                        />
                        <Route path="/advertising" element={<AdvertisingPage />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </Router>
    );
}

export default App;