import React, { useState, useEffect } from 'react';

const CouponEditor = () => {
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = () => {
        fetch('https://doordash-promo-content.lejintian.workers.dev/?section=coupons')
            .then(response => response.json())
            .then(data => setCoupons(data))
            .catch(error => console.error('Error fetching coupons:', error));
    };

    const handleCouponChange = (id, field, value) => {
        setCoupons(prevCoupons =>
            prevCoupons.map(coupon =>
                coupon.id === id ? { ...coupon, [field]: value } : coupon
            )
        );
    };

    const handleUpdateCoupons = async () => {
        try {
            const response = await fetch('https://doordash-promo-content.lejintian.workers.dev/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    section: 'coupons',
                    content: coupons
                }),
            });
            if (response.ok) {
                alert('Coupons updated successfully');
            } else {
                const errorText = await response.text();
                alert(`Failed to update coupons: ${errorText}`);
            }
        } catch (error) {
            console.error('Error updating coupons:', error);
            alert(`Error updating coupons: ${error.message}`);
        }
    };

    const handleAddCoupon = () => {
        const newCoupon = {
            id: Date.now(),
            title: '',
            code: '',
            description: ''
        };
        setCoupons([...coupons, newCoupon]);
    };

    const handleRemoveCoupon = (id) => {
        setCoupons(coupons.filter(coupon => coupon.id !== id));
    };

    return (
        <div className="max-w-4xl mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">优惠券编辑</h2>
            {coupons.map(coupon => (
                <div key={coupon.id} className="mb-4 p-4 border rounded">
                    <input
                        type="text"
                        value={coupon.title}
                        onChange={(e) => handleCouponChange(coupon.id, 'title', e.target.value)}
                        className="w-full p-2 border rounded mb-2"
                        placeholder="标题"
                    />
                    <input
                        type="text"
                        value={coupon.code}
                        onChange={(e) => handleCouponChange(coupon.id, 'code', e.target.value)}
                        className="w-full p-2 border rounded mb-2"
                        placeholder="优惠码"
                    />
                    <textarea
                        value={coupon.description}
                        onChange={(e) => handleCouponChange(coupon.id, 'description', e.target.value)}
                        className="w-full p-2 border rounded mb-2"
                        placeholder="描述"
                        rows="2"
                    />
                    <button
                        onClick={() => handleRemoveCoupon(coupon.id)}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mr-2"
                    >
                        删除优惠券
                    </button>
                </div>
            ))}
            <button
                onClick={handleAddCoupon}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mr-2"
            >
                添加新优惠券
            </button>
            <button
                onClick={handleUpdateCoupons}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                保存所有更改
            </button>
        </div>
    );
};

export default CouponEditor;