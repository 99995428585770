import React, { useState } from 'react';
import { FaCopy, FaCheck } from 'react-icons/fa';

const CouponItem = ({ coupon }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(coupon.code);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-6 hover-lift">
            <h3 className="text-2xl font-bold mb-3 text-primary font-display">{coupon.title}</h3>
            <p className="mb-4 text-gray-600">{coupon.description}</p>
            <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                <span className="text-secondary font-bold text-xl">{coupon.code}</span>
                <button 
                    onClick={copyToClipboard}
                    className={`${copied ? 'bg-green-500' : 'bg-primary'} text-white px-6 py-2 rounded-full hover:bg-opacity-90 transition-all flex items-center`}
                >
                    {copied ? <><FaCheck className="mr-2" /> Copied</> : <><FaCopy className="mr-2" /> Copy code</>}
                </button>
            </div>
        </div>
    );
};

export default CouponItem;