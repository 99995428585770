import React from 'react';
import CouponItem from './CouponItem';

const CouponList = ({ coupons }) => (
    <div className="space-y-6">
        {coupons.map((coupon) => (
            <CouponItem key={coupon.id} coupon={coupon} />
        ))}
    </div>
);

export default CouponList;